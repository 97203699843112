<template>
  <div class="dash">
    <section id="heading" class="container">
      <h2>
        welcome back, <a @click.prevent="toProfile()" class="a_link">{{ user.name }}</a>
        <small>here you can manage your account</small>
      </h2>
    </section>

    <section id="me" class="container article">
      <div class="row">
        <div class="col-sm-12 col-md-7 sm-space">
          <div class="card card_large fluid">
            <div class="text">
              <h2>
                account

                <small>user: {{ user.name }}</small>
                <small>email: {{ user.email }}</small>

                <button class="action avatar" @click="updateAvatar()">
                  update avatar
                </button>

                <button class="action" @click="changePassword()">
                  change password
                </button>

                <button
                  class="action hwid"
                  @click="resetHWID()"
                  :disabled="!enabledSubscription()"
                >
                  hwid reset
                </button>
              </h2>
            </div>

            <img :src="user.avatar" class="dash_avatar" />
          </div>
        </div>

        <div class="col-sm-12 col-md-5">
          <div class="card card_large fluid">
            <div class="text">
              <h2>
                subscription

                <small>type: {{ sub.type }}</small>
                <small>expires: {{ sub.alias ?? sub.expires }}</small>

                <button
                  class="action"
                  :disabled="enabledSubscription()"
                  @click="activeKey()"
                >
                  reedem key
                </button>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="cheat" class="container article">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="card card_large fluid">
            <div class="text">
              <h2>
                loader

                <small>status: {{ cheat }}</small>

                <button
                  class="action"
                  :disabled="!enabledSubscription() || !cheatIsOnline()"
                  @click.prevent="downloadLoader()"
                >
                  download
                </button>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import User from "@/services/user";
import moment from "moment";
import request from "@/utils/axios";
import "@/assets/css/dash.css";

export default {
  data() {
    return {
      user: Object,
      sub: Object,
      cheat: "offline",
    };
  },
  methods: {
    ...User,
    enabledSubscription() {
      let expires = moment(this.sub.expires, "DD/MM/YYYY");
      let now = moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY");
      let days = expires.diff(now, "days");

      if (days <= 0) {
        days = 0;
      }

      if (this.sub.type == "expired" || days == 0) {
        return false;
      }

      return true;
    },
    cheatIsOnline() {
      return this.cheat == "online";
    },
    getSyncCheatStatus() {
      request.get("/cheat/status").then((response) => {
        this.cheat = response.data.status;
      });
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    this.sub = this.user.sub;
    this.getSyncCheatStatus();

    if (this.sub.type == "lifetime") {
      this.sub.alias = "06/06/0666";
    }

    if (this.sub.type == "expired") {
      this.sub.alias = "invalid";
    }
  },
};
</script>

<style scoped>
.card_large img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.action {
  margin: 15px 10px 0 0;
}

.avatar {
  background: #313131;
  color: #fff;
}
</style>