import Swal from 'sweetalert2';
// import SwalAxios from '@/utils/swal-axios.js';
import Cookies from 'js-cookie'
import request from '@/utils/axios';
import JsFileDownloader from 'js-file-downloader';

export default {
  async updateAvatar () {
    const form = new FormData();
    const { value: file } = await Swal.fire({
      title: '',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'Upload your profile picture',
      },
    });

    if (!file) {
      return;
    }
    
    form.append('avatar', file, file.name);

    request
      .post('/user/avatar', form)
      .then((response) => {
        let data = response.data;

        if(data.icon == 'success') {
          this.user.avatar = data.data.url;
          this.$store.dispatch('setUser', this.user);
        }
      });
  },
  async changePassword() {
    const { value: formValues } = await Swal.fire({
      title: '',
      html: `
        <input type='password' id='swal-input1' class='swal2-input' placeholder='current password'>
        <input type='password' id='swal-input2' class='swal2-input' placeholder='new password'>
        <input type='password' id='swal-input3' class='swal2-input' placeholder='confirm new password'>
      `,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value,
          document.getElementById('swal-input3').value,
        ];
      },
    });

    let currentPassword = formValues[0];
    let newPassword = formValues[1];
    let newPasswordConfirm = formValues[2];

    if (currentPassword == newPassword) {
      return Swal.fire({
        title: 'Invalid password!',
        text: 'your new password must not be the same as the previous one.',
        icon: 'error',
      });
    }

    if (newPassword != newPasswordConfirm) {
      return Swal.fire({
        title: 'Invalid password!',
        text: 'new passwords do not match.',
        icon: 'error',
      });
    }

    if (newPassword.length < 6) {
      return Swal.fire({
        title: 'Invalid password!',
        text: 'your password must contain at least 6 characters.',
        icon: 'error',
      });
    }

    request.post('/user/password', {
      password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirm
    });
  },
  async activeKey() {
    const { value: formValues } = await Swal.fire({
      title: '',
      html: `<input type='text' id='swal-input3' class='swal2-input' placeholder='your key'>`,
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById('swal-input3').value];
      },
    });

    if (formValues) {
      let key = formValues[0];
      let keySize = 29;

      if (key.length < keySize) {
        return Swal.fire({
          title: 'Activation error!',
          text: 'your activation key appears to be invalid.',
          icon: 'error',
        });
      }

      request.post('/user/key', {
        code: key
      })
        .then((response) => {
          let data = response.data;

          if(data.icon != 'success') {
            return;
          }

          let sub = data.data.key;
          let types = [
            'expired',
            'mensal',
            'lifetime'
          ];

          this.user.sub = {
            expires: sub.expires_at,
            type: types[sub.type]
          };

           this.$store.dispatch('setUser', this.user);
          window.location.reload();
        });
    }
  },
  async resetHWID() {
    const { value: reason } = await Swal.fire({
      input: 'textarea',
      inputLabel: '',
      inputPlaceholder: 'reason to reset your hwid. \n\nexample: staay loves spinardi...'
    });

    if (!reason) {
      return;
    }
  
    if (reason.length < 6) {
      return Swal.fire({
        title: 'Reset hwid error!',
        text: 'invalid reason to reset hwid.',
        icon: 'error',
      });
    }

    request.post('/user/hwid', {
      reason: reason
    })
      .then((response) => {
        let data = response.data;

        JSON.stringify(data);
      });
  },
  async downloadLoader() {
    const randString = '_' + (Math.random() + 1).toString(36).substring(4);

    new JsFileDownloader({ 
      url: 'https://api.xanax666.xyz/cheat/loader',
      method: 'POST',
      headers: [
        { name: 'Authorization', value: 'Bearer ' + this.user.token }
      ],
      filename: this.user.name + randString + '.exe'
    }).catch(() => {
      Cookies.remove('__user');
      Cookies.remove('__token');
      
      Swal.fire({
        title: 'Download error!',
        text: 'if error persists, open ticket on discord.',
        icon: 'error'
      }).then(() => {
        return window.location.href = '/auth/login';
      })
    });
  },
  async toProfile() {
    return this.$router.push('/profile/' + this.user.id);
  }
};
